<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>Surat Permohonan Clearance</h5>
        <p>
          <b-button
            size="sm"
            variant="success"
            class="mr-25"
            @click="viewFile(dataDoc.kak_uri)"
          >
            View
          </b-button>
          <b-link
            :href="getDoc(dataDoc.kak_uri)"
            class="btn btn-sm btn-info"
            target="_blank"
          >
            Download
          </b-link>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>Peta Rencana Program Kegiatan</h5>
        <p>
          <b-button
            size="sm"
            variant="success"
            class="mr-25"
            @click="viewFile(dataDoc.peta_uri)"
          >
            View
          </b-button>
          <b-link
            :href="getDoc(dataDoc.peta_uri)"
            class="btn btn-sm btn-info"
            target="_blank"
          >
            Download
          </b-link>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BLink,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    viewFile(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
