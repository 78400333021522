<template>
  <b-alert
    v-if="docData.is_verified !== 0"
    :variant="(docData.is_verified === 1) ? 'success' : 'warning'"
    show
  >
    <h4
      v-if="docData.is_verified !== 1"
      class="alert-heading"
    >
      Catatan Perbaikan
    </h4>
    <div
      v-if="docData.is_verified !== 1 && docData.keterangan !== ''"
      class="alert-body"
    >
      <span>{{ docData.keterangan }}</span>
    </div>
    <div
      v-if="docData.is_verified === 1"
      class="alert-body"
    >
      <span>Terverifikasi</span>
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    docData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
