import { render, staticRenderFns } from "./InfraPlatKiaServerRef.vue?vue&type=template&id=165a7b72"
import script from "./InfraPlatKiaServerRef.vue?vue&type=script&lang=js"
export * from "./InfraPlatKiaServerRef.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports