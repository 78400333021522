<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Komputasi Awan Pemerintahan</h5>
      <p>{{ infData.jenis }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kepemilikan</h5>
      <p>{{ infData.kepemilikan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Pemilik</h5>
      <p>{{ infData.nama_pemilik }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Unit Pengembang Komputasi Awan Pemerintahan</h5>
      <p>{{ infData.unit_pengembang }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Unit Operasional Komputasi Awan Pemerintahan</h5>
      <p>{{ infData.unit_operasional }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Komputasi Awan Pemerintahan</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },

}
</script>
