<template>
  <b-row
    v-if="show"
    class="text-left"
  >
    <b-col cols="12 py-1">
      <hr>
      <h5>Rekomendasi Belanja {{ title }}</h5>
    </b-col>
    <b-col
      md="12"
    >
      <b-form-group>
        <b-form-radio-group
          v-model="mode"
          :options="options"
          button-variant="outline-secondary"
          class="rekomendasi-kegiatan-radios"
          size="lg"
          name="rekomendasi"
          buttons
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="mode == 'yes'"
      cols="12"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(data, index) in daftarRekoLanjut"
          :key="index"
          class="d-flex justify-content-between"
        >
          <b-form-checkbox
            :ref="`reko-${stepRekomendasi}-${data.rekomendasi_id}`"
            v-model="selectLanjut"
            :value="data.rekomendasi_id"
            plain
          >
            {{ data.rekomendasi }}
          </b-form-checkbox>
          <div class="d-flex">
            <div
              v-if="others[`a${data.rekomendasi_id}`]"
              class="text-right"
              style="width:120px"
            >
              <b-badge
                v-for="(people, keyd) in others[`a${data.rekomendasi_id}`].author"
                :key="keyd"
                v-b-tooltip.hover
                variant="light-info"
                class="ml-25"
                size="sm"
                :title="people"
              >
                {{ findInitials(people) }}
              </b-badge>
            </div>
            <b-button
              variant="flat-secondary"
              class=""
              size="sm"
              @click="copyReko(data.rekomendasi)"
            >
              <feather-icon
                icon="CopyIcon"
                size="10"
              />
            </b-button>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <b-form-group
            label="Rekomendasi Tambahan"
            label-for="reko_description"
          >
            <b-form-textarea
              ref="vi-reko_description"
              v-model="rekoDesc"
              rows="5"
              name="reko_description"
              placeholder="Rekomendasi Tambahan"
            />
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      v-else-if="mode == 'no'"
      cols="12"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(data, index) in daftarRekoTidak"
          :key="index"
          class="d-flex justify-content-between"
        >
          <b-form-checkbox
            :ref="`reko-${stepRekomendasi}-${data.rekomendasi_id}`"
            v-model="selectTidak"
            :value="data.rekomendasi_id"
            plain
          >
            {{ data.rekomendasi }}
          </b-form-checkbox>
          <div class="d-flex">
            <div
              v-if="others[`a${data.rekomendasi_id}`]"
              class="text-right"
              style="width:120px"
            >
              <b-badge
                v-for="(people, keyd) in others[`a${data.rekomendasi_id}`].author"
                :key="keyd"
                v-b-tooltip.hover
                variant="light-info"
                class="ml-25"
                size="sm"
                :title="people"
              >
                {{ findInitials(people) }}
              </b-badge>
            </div>
            <b-button
              variant="flat-secondary"
              class=""
              size="sm"
              @click="copyReko(data.rekomendasi)"
            >
              <feather-icon
                icon="CopyIcon"
                size="10"
              />
            </b-button>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <b-form-group
            label="Rekomendasi Tambahan"
            label-for="reko_description"
          >
            <b-form-textarea
              id="vi-reko_description"
              v-model="rekoDesc"
              rows="5"
              name="reko_description"
              placeholder="Rekomendasi Tambahan"
            />
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      v-if="errorStat"
      cols="12"
    >
      <b-alert
        variant="danger"
        show
      >
        <div class="alert-body">
          Error Found: {{ errorMsg }}
        </div>
      </b-alert>
    </b-col>
    <b-col
      v-if="mode"
    >
      <b-button
        variant="outline-success"
        class="mt-0 mt-md-1"
        size="sm"
        @click="updateSave"
      >
        <span>Simpan Rekomendasi</span>
      </b-button>
      <b-button
        v-if="dataSaved"
        variant="flat-success"
        size="sm"
        class="mt-0 mt-md-2 ml-50"
      >
        <feather-icon
          icon="CheckCircleIcon"
          size="14"
        /> Rekomendasi Berhasil Disimpan
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert, BFormRadioGroup, BFormGroup, BListGroup, BListGroupItem, BRow, BCol,
  BFormCheckbox, BFormTextarea, BButton, BBadge, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataUtama: {
      type: Object,
      default: () => {},
    },
    detailProgram: {
      type: Object,
      default: () => {},
    },
    stepRekomendasi: {
      type: String,
      default: 'default',
    },
    componentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      dataSaved: false,
      title: '',
      targetUrl: '',
      mode: '',
      selectLanjut: [],
      selectTidak: [],
      daftarRekoLanjut: [],
      daftarRekoTidak: [],
      others: [],
      rekoDesc: '',
      options: [
        { text: 'Dilanjutkan', value: 'yes', variant: 'text-info' },
        { text: 'Tidak Dilanjutkan', value: 'no', variant: 'text-danger' },
      ],
      errorStat: false,
      errorMsg: '',
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    findInitials(input) {
      return input.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('')
    },
    getTemplateReko() {
      if (this.stepRekomendasi !== 'default') {
        if (this.stepRekomendasi === 'aplikasi') {
          this.title = 'Aplikasi'
          this.targetUrl = 'application-data'
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          this.title = 'Infrastuktur'
          this.targetUrl = 'infrastructure-data'
        }
        if (this.stepRekomendasi === 'non-teknis') {
          this.title = 'Non-Teknis'
          this.targetUrl = 'non-teknis-data'
        }
        if (this.stepRekomendasi === 'datainformasi') {
          this.title = 'Data dan Informasi'
          this.targetUrl = 'information-data'
        }

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.dataUtama.data_utama_id,
        }

        if (this.stepRekomendasi === 'aplikasi') {
          params.aplikasi_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          params.infrastruktur_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'non-teknis') {
          params.non_teknis_id = this.detailProgram.non_teknis_id
        }
        if (this.stepRekomendasi === 'datainformasi') {
          params.data_informasi_id = this.detailProgram.data_informasi_id
        }

        const promise1 = new Promise(resolve => {
          this.$http.get(`/recommendation/${this.targetUrl}/references?token=${localStorage.getItem('userToken')}`)
            .then(res => {
              if (res.data.status === 'success') {
                this.daftarRekoLanjut = []
                this.daftarRekoTidak = []
                res.data.data.ref_recommendations.map(value => {
                  if (value.tipe === 'CONTINUE') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoLanjut.push(reko)
                  } else if (value.tipe === 'PENDING') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoTidak.push(reko)
                  }
                  return true
                })
                resolve(true)
              }
            })
        })

        const promise2 = new Promise(resolve => {
          this.$http.get(`/recommendation/${this.targetUrl}`, {
            params,
          })
            .then(res => {
              this.selectLanjut = []
              this.selectTidak = []
              if (res.data.status === 'success') {
                res.data.data.author_recommendations.map(value => {
                  if (value.is_continue === 1) {
                    this.selectLanjut.push(value.rekomendasi_id)
                  } else {
                    this.selectTidak.push(value.rekomendasi_id)
                  }
                  this.mode = (value.is_continue === 1) ? 'yes' : 'no'
                  return true
                })

                if (res.data.data.users_recommendations.length !== 0) {
                  const keys = []
                  res.data.data.users_recommendations.map(usereco => {
                    if (keys.indexOf(usereco.rekomendasi_id) === -1) {
                      keys.push(usereco.rekomendasi_id)
                      const reko = {
                        rekomendasi: usereco.rekomendasi_text,
                        author: [usereco.fullname],
                      }
                      this.others[`a${String(usereco.rekomendasi_id)}`] = reko
                    }
                    const lrlAuth = this.others[`a${String(usereco.rekomendasi_id)}`].author

                    if (lrlAuth.indexOf(usereco.fullname) === -1) {
                      this.others[`a${String(usereco.rekomendasi_id)}`].author.push(usereco.fullname)
                    }
                    return true
                  })
                }
                resolve(true)
              }
            })
        })

        Promise.all([promise1, promise2]).then(values => {
          this.show = true
          return values
        })
      }
    },
    updateSave() {
      const check = (this.mode === 'yes') ? this.daftarRekoLanjut : this.daftarRekoTidak
      const verif = {
        data_utama_id: this.dataUtama.data_utama_id,
        tipe_rekomendasi: (this.mode === 'yes') ? 1 : 0,
        rekomendasi_id: (this.mode === 'yes') ? this.selectLanjut : this.selectTidak,
      }
      if (check.length !== 0 && verif.rekomendasi_id.length === 0) {
        this.errorStat = true
        this.errorMsg = 'Rekomendasi Belum Dipilih'
      } else {
        if (this.rekoDesc !== '') {
          verif.rekomendasi_tambahan = this.rekoDesc
        }
        if (this.stepRekomendasi === 'aplikasi') {
          verif.aplikasi_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          verif.infrastruktur_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'non-teknis') {
          verif.non_teknis_id = this.detailProgram.non_teknis_id
        }
        if (this.stepRekomendasi === 'datainformasi') {
          verif.data_informasi_id = this.detailProgram.data_informasi_id
        }

        this.sendRekomendasi(verif)
      }
    },
    sendRekomendasi(verif) {
      this.$http.post(`/recommendation/${this.targetUrl}?token=${localStorage.getItem('userToken')}`, verif)
        .then(res => {
          if (res.data) {
            this.dataSaved = true
            this.rekoDesc = ''
            this.errorStat = false
            this.errorMsg = ''
            this.getTemplateReko()

            setTimeout(() => {
              this.dataSaved = false
            }, 2000)
          }
          return res.data.status
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
