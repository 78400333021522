<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA PROGRAM</h5>
        <p>{{ dataDoc.nama_program }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA KEGIATAN</h5>
        <p>{{ dataDoc.nama_kegiatan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>BIDANG PEMERINTAHAN</h5>
        <p>{{ dataDoc.kode_model_referensi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA SUB KEGIATAN</h5>
        <p>{{ dataDoc.rincian_output }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA PENANGGUNG JAWAB</h5>
        <p>{{ dataDoc.pic }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NOMOR PENANGGUNG JAWAB</h5>
        <p>{{ dataDoc.pic_number }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>UNIT KERJA</h5>
        <p>{{ dataDoc.unit_kerja }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>INSTANSI</h5>
        <p>{{ dataDoc.instansi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>SEKTOR PEMERINTAHAN</h5>
        <p>{{ dataDoc.sektor_pemerintahan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>URUSAN PEMERINTAHAN</h5>
        <p>{{ dataDoc.urusan_pemerintahan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA URUSAN</h5>
        <p>{{ dataDoc.nama_urusan }}</p>
      </b-col>
      <b-col md="12">
        <h5>URAIAN URUSAN</h5>
        <p>{{ dataDoc.deskripsi_urusan }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
