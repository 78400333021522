<template>
  <ValidationObserver ref="observer">
    <b-row>
      <b-col
        cols="12"
      >
        <hr>
        <h5>Verifikasi Teknis {{ (stepVerifikasi !== 'dokumen') ? 'Domain ' + stepVerifikasi : stepVerifikasi }}</h5>
      </b-col>
      <b-col
        v-if="errorStat"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            Error Found: {{ errorMsg }}
          </div>
        </b-alert>
      </b-col>
      <template>
        <b-col md="6">
          <b-form-group>
            <b-form-radio-group
              v-model="mode"
              :options="options"
              button-variant="outline-secondary"
              class="verifikasi-kegiatan-radios"
              size="lg"
              buttons
              @change="updateSave(stepVerifikasi)"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="mode == 'no'"
          cols="12"
        >
          <b-form-group
            label="Keterangan Perbaikan (Min. 50 Karakter)"
            label-for="vi-deskripsi"
          >
            <validation-provider
              #default="{ errors }"
              name="Keterangan Perbaikan"
              rules="required"
            >
              <b-form-textarea
                id="vi-deskripsi"
                v-model="deskripsi"
                rows="5"
                required
                placeholder="Keterangan Perbaikan"
                @blur="updateSave(stepVerifikasi)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="dataSaved"
          cols="12"
        >
          <b-button
            variant="flat-success"
            size="sm"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="14"
            /> Data Berhasil Disimpan
          </b-button>
        </b-col>
      </template>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormRadioGroup, BFormGroup, BFormTextarea, BAlert, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataUtama: {
      type: Object,
      default: () => {},
    },
    statusAwal: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    stepVerifikasi: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      required,
      submit: false,
      mode: '',
      deskripsi: '',
      options: [
        { text: 'Terverifikasi', value: 'yes', variant: 'text-info' },
        { text: 'Perlu Perbaikan', value: 'no', variant: 'text-danger' },
      ],
      targetUrl: '',
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  watch: {
    statusAwal() {
      this.updateStatus()
    },
    actionStep() {
      this.updateSave()
    },
  },
  mounted() {
    this.mode = false
    this.updateStatus()
  },
  methods: {
    updateStatus() {
      if (this.statusAwal.is_verified !== 0) {
        this.mode = (this.statusAwal.is_verified === 1) ? 'yes' : 'no'
      } else {
        this.mode = false
      }
      this.deskripsi = this.statusAwal.keterangan
    },
    updateSave() {
      const verif = {
        data_utama_id: this.dataUtama.data_utama_id,
        status: '',
        keterangan: '',
      }
      if (this.stepVerifikasi === 'program') {
        verif.proses_bisnis_id = this.statusAwal.proses_bisnis_id
        this.targetUrl = 'business-data'
      }
      if (this.stepVerifikasi === 'dokumen') {
        verif.clearance_id = this.dataUtama.clearance_id
        this.targetUrl = 'document'
      }
      if (this.stepVerifikasi === 'non-teknis') {
        verif.non_teknis_id = this.statusAwal.non_teknis_id
        this.targetUrl = 'non-teknis'
      }
      if (this.stepVerifikasi === 'layanan') {
        verif.layanan_id = this.statusAwal.layanan_id
        this.targetUrl = 'service-data'
      }
      if (this.stepVerifikasi === 'datainformasi') {
        verif.data_informasi_id = this.statusAwal.data_informasi_id
        this.targetUrl = 'data-informasi'
      }
      if (this.stepVerifikasi === 'aplikasi') {
        verif.aplikasi_id = this.statusAwal.id
        this.targetUrl = 'application'
      }
      if (this.stepVerifikasi === 'infrastruktur') {
        verif.infrastruktur_id = this.statusAwal.id
        this.targetUrl = 'infrastruktur'
      }
      if (this.stepVerifikasi === 'keamanan') {
        verif.keamanan_id = this.statusAwal.keamanan_id
        this.targetUrl = 'security'
      }

      if (this.mode === 'yes') {
        verif.status = 'VERIFIED'
        this.$delete(verif, 'keterangan')

        this.sendVerifikasi(verif)
      } else if (this.mode === 'no') {
        verif.status = 'UNVERIFIED'
        verif.keterangan = this.deskripsi

        this.$refs.observer.validate()
          .then(result => {
            if (result === true) {
              this.sendVerifikasi(verif)
            }
          })
      }
    },
    sendVerifikasi(verif) {
      this.$http.post(`/verification/${this.targetUrl}`, verif, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.dataSaved = true
            this.errorStat = false

            setTimeout(() => {
              this.dataSaved = false
            }, 2000)
          }
          return true
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
