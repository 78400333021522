import { render, staticRenderFns } from "./ReviewKegiatan.vue?vue&type=template&id=52d14ae4"
import script from "./ReviewKegiatan.vue?vue&type=script&lang=js"
export * from "./ReviewKegiatan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports