<template>
  <section id="review-kegiatan">
    <b-row>
      <b-col
        lg="3"
        cols="12"
      >
        <kegiatan-about :detail-program="dataProgram" />
        <kegiatan-status :detail-program="dataProgram" />
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <detail-infrastruktur
          v-if="dataProgram.jenis_pengadaan_id == '2'"
          set-step="review"
        />
        <detail-aplikasi
          v-if="dataProgram.jenis_pengadaan_id == '3'"
          set-step="review"
        />
        <detail-data
          v-if="dataProgram.jenis_pengadaan_id == '6'"
          set-step="review"
        />
        <detail-non
          v-if="dataProgram.jenis_pengadaan_id == '7'"
          set-step="review"
        />
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import KegiatanAbout from './detail-kegiatan/KegiatanAbout.vue'
import KegiatanStatus from './detail-kegiatan/KegiatanStatus.vue'

import DetailInfrastruktur from './detail/DetailKegiatanInfrastruktur.vue'
import DetailAplikasi from './detail/DetailKegiatanAplikasi.vue'
import DetailData from './detail/DetailKegiatanData.vue'
import DetailNon from './detail/DetailKegiatanNon.vue'

export default {
  components: {
    BRow,
    BCol,

    KegiatanAbout,
    KegiatanStatus,

    DetailInfrastruktur,
    DetailAplikasi,
    DetailData,
    DetailNon,
  },
  data() {
    return {
      userData: getUserData(),
      dataProgram: {
        utama: {},
        program: {},
        dokumen: {},
        data_info: {},
        layanan: {},
        aplikasi: {},
        infra: {},
        keamanan: {},
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataProgram = res.data.data
          }
        })
    })
  },
}
</script>
