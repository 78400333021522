<template>
  <div>
    <b-row
      class="pt-1 border-top-dark"
    >
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>JENIS STANDAR KEAMANAN</h5>
        <p>{{ dataDoc.standar_keamanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>KETERANGAN NAMA STANDAR KEAMANAN</h5>
        <p>{{ (dataDoc.nama_standar !== '') ? dataDoc.nama_standar : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>HASIL AUDIT KEAMANAN</h5>
        <p>{{ (dataDoc.hasil_audit !== '') ? dataDoc.hasil_audit : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TANGGAL PELAKSANAN AUDIT</h5>
        <p>{{ (dataDoc.tanggal_audit !== '') ? dataDoc.tanggal_audit : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>PENERAPAN KEAMANAN</h5>
        <p>{{ (dataDoc.penerapan_keamanan !== '') ? dataDoc.penerapan_keamanan : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>PENGUJI KELAIKAN KEAMANAN</h5>
        <p>{{ (dataDoc.kelaikan_keamanan !== '') ? dataDoc.kelaikan_keamanan : '-' }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
