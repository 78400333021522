<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Domain Infrastruktur</h5>
        <p>{{ dataInfra.domain_infrastruktur }}</p>
      </b-col>
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Area Infrastruktur</h5>
        <p>{{ dataInfra.area_infrastruktur }}</p>
      </b-col>
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Kategori Infrastruktur</h5>
        <p>{{ (dataInfra.kategori_infra !== '') ? dataInfra.kategori_infra : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>{{ `Nama ${nama_infra}` }}</h5>
        <p>{{ dataInfra.nama }}</p>
      </b-col>
    </b-row>

    <template v-if="dataInfra.area_infra_id === 4">
      <infra-fasil
        ref="fasil"
        :inf-data="dataInfra"
      />
    </template>

    <template v-if="dataInfra.area_infra_id === 3">
      <infra-plat-cloud
        ref="platCloud"
        :inf-data="dataInfra"
      />
    </template>

    <template v-if="dataInfra.area_infra_id === 2">
      <infra-plat-server
        ref="platKiaServer"
        :inf-data="dataInfra"
      />
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import InfraFasil from '../template-detail-infra/InfraFasil.vue'
import InfraPlatCloud from '../template-detail-infra/InfraPlatCloudRef.vue'
import InfraPlatServer from '../template-detail-infra/InfraPlatKiaServerRef.vue'

export default {
  components: {
    BCol,
    BRow,
    InfraFasil,
    InfraPlatCloud,
    InfraPlatServer,
  },
  props: {
    dataInfra: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nama_infra: '',
    }
  },
  mounted() {
    this.nama_infra = this.dataInfra.area_infrastruktur
    if (this.dataInfra.kategori_infra !== '') {
      this.nama_infra = this.dataInfra.kategori_infra
    }
  },
}
</script>
